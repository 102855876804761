import React, { useState } from "react";
import { TransactionConfirmation } from "./TxnConfirmation";
import { TransactionDetails } from "./TxnDetails";

export function TransactionForm() {
  const [showDetails, setShowDetails] = useState(false);
  const [txnDigest, setTxnDigest] = useState("");
  const [orderKey, setOrderKey] = useState("");

  return (
    <>
      {showDetails ? (
        <TransactionDetails dataTxnDigest={txnDigest} orderKey={orderKey} />
      ) : (
        <TransactionConfirmation
          setTxnDigest={setTxnDigest}
          setShowDetails={setShowDetails}
          setOrderKey={setOrderKey}
        />
      )}
    </>
  );
}
