import { useEffect, useState } from "react";
// import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import NavigationIcon from "../../../../../assets/img/hamburger.svg";
import ScanIcon from "../../../../../assets/img/QR-scan.svg";
import RefreshIcon from "../../../../../assets/img/reload.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import suiMono from "../../../../../assets/img/aptos.svg";
import sendBtn from "../../../../../assets/img/sendBtn.svg";
import receiveBtn from "../../../../../assets/img/receiveBtn.svg";
import {
  Context,
  NetworkContext,
  shorten,
  formatAmount,
  decrypted_data,
  SignOutConfirmation,
  Loader,
  client,
  client_network,
  client_faucet_url,
  toastMsg,
} from "../../../../lib/helper";
import "../../common.css";
import { QrScanner } from "../Scanner";
import { ManageTransactionList } from "../RecentTxnList";
import { NavigationNetworkProfile } from "../SwitchNetwork";
import { CoinList } from "../CoinList";
import TxnSend from "../TxnSend";
import TxnReceive from "../TxnReceive";
import { AptosAccount, AptosClient, CoinClient, FaucetClient } from "aptos";
import { useQuery } from '@apollo/client';

export function Dashboard() {
  const navigate = useNavigate();
  const [coinValue, setCoinValue] = useState(0);
  const [showTxn, setShowTxn] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showSignOut, setShowSignOut] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const [isTxnSuccess, setIsTxnSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [showTxnSend, setShowTxnSend] = useState(false);
  const [showTxnReceive, setShowTxnReceive] = useState(false);
  const [keyPair, setKeyPair] = useState({});
  const [txnList, setTxnList] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [txnText, setTxnText] = useState("");
  const [coinText, setCoinText] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  // const [walletNetwork, setWalletNetwork] = useState("");
  // const [walletNetworkUrl, setWalletNetworkUrl] = useState("");

  const getMnemonic = localStorage.getItem("wallet:mnemonic");
  const isGLogin = localStorage.getItem("wallet:gin");
  const keys = shorten(walletAddress);

  // const fetchBalance = async (publicKey: any) => {
  //   try {
  //     const coinBalanceData = await client.getBalance({
  //       owner: `${publicKey}`,
  //     });
  //     const balanceAmount = coinBalanceData.totalBalance;
  //     const formatBalance = formatAmount(balanceAmount);
  //     setCoinValue(formatBalance);
  //     setBalanceLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching the balance:", error);
  //   }
  // };

  const fetchTransaction = async (publicKey: any) => {
    try {
      const getTransactionResponse = await client.queryTransactionBlocks({
        filter: {
          ToAddress: `${publicKey}`,
        },
        options: {
          showEffects: true,
          showBalanceChanges: true,
          showInput: true,
        },
        limit: 100,
      });
      setTxnList([]);
      if (
        getTransactionResponse &&
        getTransactionResponse.data &&
        getTransactionResponse.data.length > 0
      ) {
        setTxnText("");
        setTxnList(getTransactionResponse.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTxnText("You don't have any transactions");
      }
    } catch (error) {
      // Handle the error if needed
    }
  };


  const fetchCoinMetaData = async (getCoinKey: string, balance: number) => {
    try {


      const getCoinsMetaResponse = await client.getCoinMetadata({
        coinType: `${getCoinKey}`,
      });
      if (getCoinsMetaResponse && getCoinsMetaResponse.name) {
        // return response.data.result;
        return { metadata: getCoinsMetaResponse, balance, getCoinKey };
      }
      return null;
    } catch (error) {
      // Handle the error if needed
    }
  };

  // const fetchCoins = async (publicKey: any) => {
  //   try {
  //     const getCoinsResponse = await client.getAllCoins({
  //       owner: `${publicKey}`,
  //     });
  //     if (getCoinsResponse.data && getCoinsResponse.data.length > 0) {
  //       setCoinText("");
  //       const allCoins = getCoinsResponse.data;
  //       if (allCoins && allCoins) {
  //         const coinBalancesMap = new Map<string, number>();
  //         allCoins.forEach((item: any) => {
  //           const coinType = item.coinType;
  //           const balance = parseFloat(item.balance);
  //           if (coinBalancesMap.has(coinType)) {
  //             coinBalancesMap.set(
  //               coinType,
  //               coinBalancesMap.get(coinType)! + balance
  //             );
  //           } else {
  //             coinBalancesMap.set(coinType, balance);
  //           }
  //         });
  //         const fetchPromises = [];
  //         coinBalancesMap.forEach((balance, coinType) => {
  //           fetchPromises.push(fetchCoinMetaData(coinType, balance));
  //         });
  //         const coinDataArray = await Promise.all(fetchPromises);
  //         return coinDataArray;
  //       }
  //     } else {
  //       setCoinText("You don't have any tokens");
  //     }
  //   } catch (error) {
  //     // Handle the error if needed
  //   }
  // };

  const fetchAirdrop = async () => {
    const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
    const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";
    // Create API and faucet clients.
    const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL);

    // Create client for working with the coin module.
    setBalanceLoading(true);
    try {
      const data = await faucetClient.fundAccount(walletAddress, 100000000);
      // console.log(data);
      setBalanceLoading(false);
      fetchData(walletAddress);

      // if (data?.[0]) {
      //   const timeout = setTimeout(() => {
      //     setBalanceLoading(false);
      //   }, 15000);
      // }
    } catch (error) {
      toastMsg("Rate Limit");
      setBalanceLoading(false);
      console.error("There was an error fetching the data:", error);
    }
  };

  // const fetchTokenData = async (getPublicKey: string) => {
  //   const coinDataArray = await fetchCoins(getPublicKey);
  //   setCoinList(coinDataArray);
  // };

  const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
  const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";

  const fetchBalance = async (getPublicKey: string) => {
    const client = new AptosClient(NODE_URL);
    const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL); // <:!:section_1

    // Create client for working with the coin module.
    // :!:>section_1a
    const coinClient = new CoinClient(client)
    const run = client.getAccountTransactions("0xb91fcf192ffbfa120e17408c36fb7609c50a71a5c7245fbf60826e533b487d63")
    console.log(run, 'tx');
    // const bal = await coinClient.checkBalance(getPublicKey)
    // setCoinValue(formatAmount(bal as any))
    try {
      const bal = await coinClient.checkBalance(getPublicKey);
      const formattedBal = formatAmount(bal);
      const numericBal = parseFloat(formattedBal);
      setCoinValue(numericBal);
      // setCoinValue(formatAmount(bal as any));
    } catch (error) {
      console.error("Error checking balance:", error);
      // Handle the error gracefully, e.g., show an error message to the user.
    }

  }


  const onCopyText = () => {
    navigator.clipboard.writeText(walletAddress && walletAddress);
    toastMsg("Copied!");
  };

  const fetchData = (walletAddress: any) => {
    setLoading(true);
    fetchBalance(walletAddress);
    // fetchTokenData(walletAddress);
    fetchTransaction(walletAddress);
  };

  const getDataKeyPair = () => {
    const TEST_MNEMONICS = decrypted_data(getMnemonic as any);

    const derivationPath = `m/44'/637'/0'/0'/0'`;
    // const provider = new Provider(Network.TESTNET);
    const getPair = AptosAccount.fromDerivePath(derivationPath, TEST_MNEMONICS);
    // console.log(getPair, 'aptos');
    // const getPair = Ed25519Keypair.deriveKeypair(TEST_MNEMONICS);
    const getWalletKey = getPair && getPair.address().hex();
    // console.log(getWalletKey);

    setKeyPair(getPair);
    setWalletAddress(getWalletKey as any);
    fetchData(getWalletKey);
  };

  useEffect(() => {
    if (walletAddress !== "" && isTxnSuccess) {
      fetchData(walletAddress);
      setIsTxnSuccess(false);
    }
  }, [isTxnSuccess]);

  useEffect(() => {
    // const getNetwork = localStorage.getItem("network:name");
    // const getNetworkUrl = localStorage.getItem("network:url");

    // if (
    //   getNetwork !== "" &&
    //   getNetwork !== null &&
    //   getNetwork !== undefined &&
    //   getNetworkUrl !== "" &&
    //   getNetworkUrl !== null &&
    //   getNetworkUrl !== undefined
    // ) {
    //   setWalletNetwork(decrypted_data(getNetwork));
    //   setWalletNetworkUrl(decrypted_data(getNetworkUrl));
    // }

    if (window.location.hash !== "") {
      navigate("/", { replace: true });
    }

    if (isGLogin == "yes") {
      const userKey = localStorage.getItem("wallet:address");
      setWalletAddress(decrypted_data(userKey as any));
      fetchData(decrypted_data(userKey as any));
    }

    if (
      getMnemonic !== "" &&
      getMnemonic !== null &&
      getMnemonic !== undefined
    ) {
      getDataKeyPair();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <>
        <div className="dashboard-bottom-bg"></div>
        {loading ? (
          <Loader setLoading={setLoading} />
        ) : showScanner ? (
          <Context.Provider
            value={{
              coinValue,
              setShowScanner,
              walletAddress,
              setIsTxnSuccess,
            }}
          >
            <QrScanner keyPair={keyPair} coinList={coinList} />
          </Context.Provider>
        ) : showTxnSend ? (
          <TxnSend
            identityKey={false}
            coinValue={coinValue}
            coinList={coinList}
            setShowTxnSend={setShowTxnSend}
            keyPair={keyPair}
            setIsTxnSuccess={setIsTxnSuccess}
          />
        ) :
          showTxnReceive ? (
            <TxnReceive
              setShowTxnReceive={setShowTxnReceive}
              walletAddress={walletAddress}
            />
          ) : (
            <>
              <div className="dashboard-header">
                <img
                  src={NavigationIcon}
                  alt="BlockBolt"
                  onClick={() => setShowNavigation(true)}
                />
                <div className="airdropSui" onClick={() => fetchAirdrop()}>
                  <p>Faucet</p>
                  <img src={suiMono} alt="suiMono" />
                </div>
              </div>
              <div className="dashboard-wrapper">
                <div className="dashboard-main">
                  <div className="data-content">
                    <p>Available Balance (APT)</p>
                    <img
                      src={RefreshIcon}
                      className="refresh-icon"
                      alt="BlockBolt"
                      onClick={() => {
                        fetchData(walletAddress && walletAddress),
                          toastMsg("Refreshed");
                      }}
                    />
                  </div>
                  <div className="balance-content">
                    <div>
                      <h4>{balanceLoading ? "Loading..." : coinValue}</h4>
                      <h6 className="idCopyDiv">
                        {keys}
                        <img
                          src={CopyIcon}
                          className="copy_icon"
                          onClick={() => onCopyText()}
                        />
                      </h6>
                    </div>
                    <img
                      className="scannerIcon"
                      src={ScanIcon}
                      alt="BlockBolt"
                      onClick={() => setShowScanner(true)}
                    />
                  </div>
                  <div className="sendReceiveBtns">
                    <button
                      className="wallet-common-button"
                      onClick={() => setShowTxnSend(true)}
                    >
                      Send
                      <img className="sendBtn" src={sendBtn} alt="Send Payment" />
                    </button>
                    <button
                      className="wallet-common-button"
                      onClick={() => setShowTxnReceive(true)}
                    >
                      Receive
                      <img
                        className="receiveBtn"
                        src={receiveBtn}
                        alt="Send Payment"
                      />
                    </button>
                  </div>
                </div>
                <div className="dashboardTabs">
                  <h5
                    className={
                      showTxn
                        ? "dashboard-activities"
                        : "active dashboard-activities"
                    }
                    onClick={() => setShowTxn(false)}
                  >
                    Tokens
                  </h5>
                  <h5
                    className={
                      showTxn
                        ? "active dashboard-activities"
                        : "dashboard-activities"
                    }
                    onClick={() => setShowTxn(true)}
                  >
                    Transactions
                  </h5>
                </div>
                <div className="dashboard-activities-wrapper">
                  {showTxn ? (
                    <>
                      {txnText != "" && <h6>{txnText}</h6>}
                      {txnList &&
                        txnList.map((txn) => (
                          <ManageTransactionList
                            txn={txn}
                            walletNetwork={client_network}
                            walletAddress={walletAddress}
                          />
                        ))}
                    </>
                  ) : (
                    <>
                      {coinText != "" && <h6>{coinText}</h6>}
                      {coinList &&
                        coinList.map((coin) => <CoinList coin={coin} />)}
                    </>
                  )}
                </div>
                <button
                  className="wallet-common-button"
                  onClick={() => setShowScanner(true)}
                >
                  Scan QR
                </button>
              </div>
            </>
          )}
        {showNavigation && (
          <NetworkContext.Provider
            value={{
              // walletNetwork,
              // setWalletNetwork,
              // setWalletNetworkUrl,
              setShowSignOut,
            }}
          >
            <NavigationNetworkProfile setShowNavigation={setShowNavigation} />
          </NetworkContext.Provider>
        )}
        {showSignOut && <SignOutConfirmation setShowSignOut={setShowSignOut} />}
      </>
    </>
  );
}
