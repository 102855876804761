import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AptosClient,
  CoinClient,
} from "aptos";
import { isValidSuiAddress } from "@mysten/sui.js/utils";
import CloseIcon from "../../../../../assets/img/close.svg";
import CheckIcon from "../../../../../assets/img/check-new.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import {
  TransactionLoader,
  shorten,
  toastMsg,
} from "../../../../lib/helper";

const TxnSend = (props: any) => {
  const {
    identityKey,
    coinValue,
    coinList,
    setShowTxnSend,
    keyPair,
    setIsTxnSuccess,
  } = props;
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [txnDigest, setTxnDigest] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isPosTxn, setIsPosTxn] = useState(false);
  const [deviceId, setDeviceId] = useState("");

  const onCopyText = () => {
    navigator.clipboard.writeText(txnDigest);
    toastMsg("Copied!");
  };

  function convertToOctas(inputAmount: string): number {
    const APT_TO_OCTAS = 100_000_000;

    if (!isNaN(Number(inputAmount))) {
      return Math.round(Number(inputAmount) * APT_TO_OCTAS);
    } else {
      // console.error("Invalid amount value:", inputAmount);
      return 0; // return 0 for invalid values
    }
  }

  const txnWithSoundBox = async (getAmount: any) => {
    const sound_box_url =
      "https://api-fintech-terminal-management.bonrix.in/create_wav_file";
    const sound_provider_id = "CZWguXGUlhBNiG5brX2g4tPIdW0kEsO1";
    const coin = "APT";

    const data = {
      device_id: deviceId,
      ip_key: sound_provider_id,
      text: `Received ${getAmount} ${coin} on APTOS chain On Blockbolt`,
    };

    try {
      const response = await fetch(sound_box_url, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          crossOrigin: "anonymous",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }

    // try {
    //   axios
    //     .post(sound_box_url, data)
    //     .then(() => {
    //       // console.log('success');
    //     })
    //     .catch(() => {
    //       // console.log('fail');
    //     });
    // } catch (error: any) {
    //   // console.log('error', error);
    // }
  };

  const onPaymentSend = async () => {
    setShowLoading(true)
    try {
      const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
      // const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";
      // Create API and faucet clients.
      const client = new AptosClient(NODE_URL);
      // const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL);
      // Create client for working with the coin module.
      const coinClient = new CoinClient(client);
      const octasValue = convertToOctas(amount);
      // if (octasValue !== null) {
      //   return  // Outputs: 10000000 for userInput = "0.1"
      // }
      // const provider = new Provider(Network.TESTNET);
      const tran = await coinClient.transfer(
        keyPair,
        // "0xef3a1a1ab3915aed6490a917d5b97ef32568f9c5772e35f44fc4de67a625f874",
        address,
        octasValue,
        {
          gasUnitPrice: BigInt(100),
        }
      );
      // console.log(tran);
      const hashh = await client.waitForTransaction(tran, {
        checkSuccess: true,
      });
      if (isPosTxn) {
        txnWithSoundBox(amount);
        setTxnDigest(tran);
        setShowLoading(false);

      } else {
        setTxnDigest(tran);
        setShowLoading(false);
      }


    } catch (error) {
      console.error("Error sending payment:", error);
    }
  }

  useEffect(() => {
    if (identityKey !== false) {
      const isSuiAddress = isValidSuiAddress(identityKey);
      if (isSuiAddress) {
        setAddress(identityKey);
      } else {
        const parsedData = JSON.parse(identityKey);
        if (parsedData.merchant_address) {
          setIsPosTxn(true);
          setDeviceId(parsedData.machine_id);
          setAddress(parsedData.merchant_address);
        }
      }
    }
  }, []);

  return (
    <>
      {showLoading && <div className="black-bg"></div>}
      <div className="txn-send-wrapper">
        <div className="txn-heading-content inner_header">
          {txnDigest != "" ? (
            <>
              <img
                src={CloseIcon}
                alt="Back"
                onClick={() => {
                  setIsTxnSuccess(true), setShowTxnSend(false);
                }}
              />
              <h5>BlockBolt</h5>
            </>
          ) : (
            <>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => setShowTxnSend(false)}
              />
              <h5>Send Coin</h5>
            </>
          )}
        </div>
        <div>
          {txnDigest !== "" ? (
            <div className="txn-send-success">
              <img src={CheckIcon} alt="BlockBolt" className="check_icon" />
              <h4>Transaction completed successfully</h4>
              <div className="send_success_details">
                <p>
                  Amount{" "}
                  <span className="send_success_details_inner">
                    {amount} APT
                  </span>
                </p>
                <p>
                  To{" "}
                  <span className="send_success_details_inner">
                    {shorten(address)}
                  </span>
                </p>
                <p className="transaction_ID">
                  TXID{" "}
                  <span className="send_success_details_inner">
                    {shorten(txnDigest)}
                    <img
                      src={CopyIcon}
                      onClick={() => onCopyText()}
                      className="copy_icon"
                    />
                  </span>
                </p>
              </div>
              <div className="explorer wallet-common-button">
                <button
                  className="wallet-common-button"
                  onClick={() => {
                    setIsTxnSuccess(true), setShowTxnSend(false);
                  }}
                >
                  Back to main
                </button>
              </div>
            </div>
          ) : (
            <div className="txn-send-form">
              <p>Amount</p>
              <input
                type="number"
                className="form-control"
                placeholder="0.01"
                onChange={(e) => setAmount(e.target.value)}
              />
              <p>Address</p>
              {identityKey == false ? (
                <input
                  type="text"
                  className="form-control"
                  placeholder="0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63"
                  onChange={(e) => setAddress(e.target.value)}
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  disabled
                  value={address}
                />
              )}
              {/* <div className="selectCoin"> */}
              {/* <p>Select Coin</p> */}
              {/* <div className="select-field-wrapper">
                  <select
                    className="input selectCoinInner"
                    id="coin"
                    name="coin"
                    value={coinName}
                    onChange={(e) => setCoinName(e.target.value)}
                    defaultValue="SUI"
                  >
                    {coinList.map((coin: any) => {
                      return (
                        <option
                          value={coin.metadata.name}
                          key={coin.metadata.name}
                        >{`${coin.metadata.name}`}</option>
                      );
                    })}
                  </select>
                </div> */}
              {/* </div> */}
              <div className="gas_fees">
                <p>Estimated Gas Fees</p>
                <p>--</p>
              </div>

              <button
                className="wallet-common-button"
                onClick={() => onPaymentSend()}
              >
                Send
              </button>
            </div>
          )}
        </div>
        {showLoading && <TransactionLoader />}
      </div>
    </>
  );
};

export default TxnSend;
