import React, { useEffect, useState } from "react";
import moment from "moment";
import SentIcon from "../../../../../assets/img/sent.svg";
import ReceiveIcon from "../../../../../assets/img/receive.svg";
import { client_network } from "../../../../lib/helper";

export function ManageTransactionList(props: any) {
  const { txn, walletAddress, walletNetwork } = props;
  const [txnLabel, setTxnLabel] = useState("");
  const [txnDigest, setTxnDigest] = useState("");
  const [txnTime, setTxnTime] = useState("");

  useEffect(() => {
    if (txn && txn.effects) {
      const transactionEffects = txn.effects;
      const verifyStatus = transactionEffects.status.status;
      if (verifyStatus === "success") {
        if (
          transactionEffects.gasObject &&
          transactionEffects.gasObject.owner
        ) {
          const isOwner = transactionEffects.gasObject.owner.AddressOwner;
          if (isOwner == walletAddress) {
            setTxnLabel("Sent");
            const transactionDigest = transactionEffects.transactionDigest;
            setTxnDigest(transactionDigest);
            const getTimeStamp = parseInt(txn.timestampMs);
            const timestamp = moment(getTimeStamp).format("lll");
            setTxnTime(timestamp);
          } else {
            setTxnLabel("Received");
            const transactionDigest = transactionEffects.transactionDigest;
            setTxnDigest(transactionDigest);
            const getTimeStamp = parseInt(txn.timestampMs);
            const timestamp = moment(getTimeStamp).format("lll");
            setTxnTime(timestamp);
          }
        }
      }
    }
  }, []);

  return (
    <>
      {/* {txnLabel && txnDigest && txnTime && (
        <a
          href={
            "https://explorer.sui.io/txblock/" +
            txnDigest +
            "?network=" +
            client_network
          }
          target="_blank"
        >
          <div className="txn-activities-wrapper">
            {txnLabel === "Sent" ? (
              <img src={SentIcon} alt="BlockBolt" />
            ) : (
              <img src={ReceiveIcon} alt="BlockBolt" />
            )}

            <div className="txn-activities-content">
              <h5>{txnLabel}</h5>
              <p>{txnTime}</p>
            </div>
          </div>
        </a>
      )} */}
    </>
  );
}
