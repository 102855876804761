import { useState, useContext, useRef, useEffect } from "react";
// import { TransactionBlock } from "@mysten/sui.js/transactions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
// import { getZkLoginSignature } from "@mysten/zklogin";
// import axios from "axios";
import {
  Context,
  TransactionLoader,
  TxnContext,
  client,
  // client_fullnode_url,
  client_network,
  decrypted_data,
  toastMsg,
} from "../../../../lib/helper";
// import {
//   IDO_RPC_INVESTING,
//   IDO_PACKAGE_TESTNET,
//   Boltpay,
//   getBoltpay,
// } from "../../../../lib/ido_contract";
import {
  // SUI_COIN_TYPE,
  // SUI_PACKAGE_TESTNET,
  // SUI_WOOCOMMERCE_TESTNET,
  // SUI_WOOCOMMERCE_TREASURY,
  getSuiConfig,
} from "../../../../lib/sui_contract";
import "../../common.css";
import CloseIcon from "../../../../../assets/img/close.svg";

import {
  // Provider,
  // Network,
  // AptosAccount,
  AptosClient,
  CoinClient,
  FaucetClient,
} from "aptos";

interface ITxnObject {
  merchant_address: string;
  merchant_name: string;
  order_id: number;
  amount: string;
  network: string;
  blockchain: string;
  coin_name: string;
}


export function TransactionConfirmation(props: any) {
  const isGLogin = localStorage.getItem("wallet:gin");
  const { setTxnDigest, setShowDetails, setOrderKey } = props;
  const { setShowScanner, coinValue, walletAddress } = useContext(Context);
  const { scanResultWebCam, keyPair } = useContext(TxnContext);
  const [showLoading, setShowLoading] = useState(false);
  // const [txnObject, setTxnObject] = useState({});
  const [txnObject, setTxnObject] = useState<ITxnObject | null>(null);

  const refIsReloadInProgress = useRef(false);
  let { rpc, packageId } = getSuiConfig(`${client_network}`);



  function convertToOctas(inputAmount: string): number {
    const APT_TO_OCTAS = 100_000_000;

    if (!isNaN(Number(inputAmount))) {
      return Math.round(Number(inputAmount) * APT_TO_OCTAS);
    } else {
      console.error("Invalid amount value:", inputAmount);
      return 0; // return 0 for invalid values
    }
  }

  const createTransaction = async () => {
    setShowLoading(true); // Temporary loading state
    const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
    const client = new AptosClient(NODE_URL);
    const coinClient = new CoinClient(client);

    const octasValue = convertToOctas(txnObject!.amount);
    const merchName = txnObject?.merchant_name;
    const payload = {
      function: "0x4245dd2956c68ae3ea9b5ee255dce19bbf1b9b2244049f320371bee234c00514::blockbolt_pay::send",
      type_arguments: ["0x1::aptos_coin::AptosCoin"],
      arguments: [
        octasValue, // amount
        txnObject?.merchant_address, // receiver
        txnObject?.order_id, // order id
        merchName, // merchant name
        "0x4245dd2956c68ae3ea9b5ee255dce19bbf1b9b2244049f320371bee234c00514" // some identifier
      ],
    };

    // If you want to handle different coin names
    // Assuming there was supposed to be a check for different coin names
    // if (txnObject?.coin_name.toLowerCase() === "apt") {
    const rawTxn = await client.generateTransaction(keyPair.address(), payload);
    const bcsTxn = AptosClient.generateBCSTransaction(keyPair, rawTxn);
    const transactionRes = await client.submitSignedBCSTransaction(bcsTxn);

    setTxnDigest(transactionRes.hash);
    setShowLoading(false);
    setShowDetails(true);
    // } else {
    //     // Handle other coin names, if needed
    //     const tran = await coinClient.transfer(
    //         keyPair,
    //         txnObject!.merchant_address,
    //         1000,
    //         {
    //             gasUnitPrice: BigInt(100),
    //         }
    //     );

    //     const txHash = await client.waitForTransaction(tran, {
    //         checkSuccess: true,
    //     });
    //     // setTxnDigest(txHash);
    //     // setShowLoading(false);
    // }

    // Uncomment the function below if you need to trigger a transaction
    // const triggerTx = async () => {
    //     const NODE_URL = "https://fullnode.testnet.aptoslabs.com";
    //     const FAUCET_URL = "https://faucet.testnet.aptoslabs.com";
    //     const client = new AptosClient(NODE_URL);
    //     const faucetClient = new FaucetClient(NODE_URL, FAUCET_URL);
    //     const coinClient = new CoinClient(client);

    //     const tran = await coinClient.transfer(
    //         keyPair,
    //         "0xef3a1a1ab3915aed6490a917d5b97ef32568f9c5772e35f44fc4de67a625f874",
    //         1000,
    //         {
    //             gasUnitPrice: BigInt(100),
    //         }
    //     );

    //     const hashh = await client.waitForTransaction(tran, {
    //         checkSuccess: true,
    //     });
    // };
  };





  useEffect(() => {
    if (scanResultWebCam) {
      const parsedData = JSON.parse(scanResultWebCam);
      // console.log(parsedData);

      setTxnObject(parsedData);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>
      <div className="common_center_parent">
        <div className="transaction-wrapper">
          {showLoading ? (
            <TransactionLoader />
          ) : (
            <>
              {txnObject !== null && (
                <>
                  <img
                    src={CloseIcon}
                    alt="BlockBolt"
                    className="transaction-close-btn"
                    onClick={() => setShowScanner(false)}
                  />
                  <>
                    <div className="transaction-content">
                      <p>To </p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter address"
                        value={`${txnObject.merchant_address}`}
                      />
                      <p>Label</p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter label"
                        value={`${txnObject.merchant_name}`}
                      />
                      <p>Amount</p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                        value={txnObject.amount}
                      />
                    </div>
                    <button
                      className="transaction-btn wallet-common-button"
                      onClick={() => createTransaction()}
                    >
                      Pay Now
                    </button>
                  </>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
