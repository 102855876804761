// import SuiMono from "../../../../../assets/img/suiMono.png";
import suiMono from "../../../../../assets/img/sui-mono.svg"
import { formatAmount } from "../../../../lib/helper";

export function CoinList(props: any) {
  const { coin } = props;
  return (
    <div className="coin-list-wrapper " key={coin.metadata.id}>
      <div className="coin-img-wrapper">
        {coin.metadata.iconUrl !== null ? (
          <img src={`${coin.metadata.iconUrl}`} />
        ) : (
          coin.metadata.iconUrl == null && <img src={suiMono} id="suiMono" />
        )}
      </div>
      <div className="coin-content-wrapper">
        <h5>{coin.metadata.name}</h5>
        <p>{formatAmount(coin.balance)}</p>
      </div>
    </div>
  );
}
