import { createContext, useState } from "react";
import BigNumber from "bignumber.js";
import { SuiClient, getFullnodeUrl } from "@mysten/sui.js/client";
import CloseIcon from "../../assets/img/close.svg";
import "../pages/Wallet/common.css";
import { toast } from "react-toastify";

export const Context = createContext();
export const NetworkContext = createContext();
export const TxnContext = createContext();

export const CLIENT_NAME = "buildon.sui";
export const CLIENT_ADDRESS =
  "0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63";

export const client_network = "testnet";
export const client_network_url = "https://wallet-rpc.testnet.sui.io/";
export const client_faucet_url = "https://faucet.testnet.sui.io/v1/gas";
export const client_fullnode_url = "https://fullnode.testnet.sui.io/";
export const client = new SuiClient({
  url: getFullnodeUrl(`${client_network}`),
});

export const toastMsg = (data: any) =>
  toast(data, {
    position: "bottom-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export function shorten(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

// export function formatAmount(
//   amount?: BigNumber | bigint | number | string | null
// ) {
//   if (typeof amount === "undefined" || amount === null) {
//     return "--";
//   }

//   let bn = new BigNumber(amount.toString());

//   if (bn.gte(1_000_000_000)) {
//     bn = bn.shiftedBy(-9);
//   } else if (bn.gte(1_000_000)) {
//     bn = bn.shiftedBy(-9);
//   } else if (bn.gte(10_000)) {
//     bn = bn.shiftedBy(-3);
//   }

//   if (bn.gte(1)) {
//     bn = bn.decimalPlaces(3, BigNumber.ROUND_DOWN);
//   }

//   return bn.toFormat();
// }

export function formatAmount(
  amount?: BigNumber | bigint | number | string | null
) {
  if (typeof amount === "undefined" || amount === null) {
    return "--";
  }

  let bn = new BigNumber(amount.toString());

  if (bn.gte(1_000_000_000_000_000)) { // 10^15
    bn = bn.shiftedBy(-8);
  } else if (bn.gte(1_000_000_000_000)) { // 10^12
    bn = bn.shiftedBy(-8);
  } else if (bn.gte(1_000_000_000)) {  // 10^9
    bn = bn.shiftedBy(-8);
  } else if (bn.gte(10_000_000)) { // 10^7
    bn = bn.shiftedBy(-8);
  }

  if (bn.gte(1)) {
    bn = bn.decimalPlaces(3, BigNumber.ROUND_DOWN);
  }

  return bn.toFormat();
}

export function encrypted_data(string: string) {
  string = unescape(encodeURIComponent(string));
  var newString = "",
    char,
    nextChar,
    combinedCharCode;
  for (var i = 0; i < string.length; i += 2) {
    char = string.charCodeAt(i);

    if (i + 1 < string.length) {
      nextChar = string.charCodeAt(i + 1) - 31;

      combinedCharCode =
        char +
        "" +
        nextChar.toLocaleString("en", {
          minimumIntegerDigits: 2,
        });

      newString += String.fromCharCode(parseInt(combinedCharCode, 10));
    } else {
      newString += string.charAt(i);
    }
  }
  return newString;
}

export function decrypted_data(string: string) {
  var newString = "",
    char,
    codeStr,
    firstCharCode,
    lastCharCode;
  if (string && string.length > 1) {
    for (var i = 0; i < string.length; i++) {
      char = string.charCodeAt(i);
      if (char > 132) {
        codeStr = char.toString(10);

        firstCharCode = parseInt(codeStr.substring(0, codeStr.length - 2), 10);

        lastCharCode =
          parseInt(codeStr.substring(codeStr.length - 2, codeStr.length), 10) +
          31;

        newString +=
          String.fromCharCode(firstCharCode) +
          String.fromCharCode(lastCharCode);
      } else {
        newString += string.charAt(i);
      }
    }
  }
  return newString;
}

export function Loader({ setLoading }) {
  return (
    <div className="loader-wrapper">
      <img src={CloseIcon} alt="close" onClick={() => setLoading(false)} />
      <div className="box">
        <div className="loader-15"></div>
      </div>
    </div>
  );
}

export function TransactionLoader() {
  return (
    <div className="transaction-loader-wrapper">
      <div className="box">
        <div className="loader-15"></div>
      </div>
      <h4>Please wait...</h4>
      <p>Your transaction is currently being processed.</p>
    </div>
  );
}

export function SignOutConfirmation(props: any) {
  const { setShowSignOut } = props;
  const [showLoader, setShowLoader] = useState(false);

  const manageSignOut = () => {
    setShowLoader(true);
    localStorage.clear();
    location.reload();
  };
  return (
    <>
      <div className="black-bg"></div>
      {showLoader ? (
        <Loader setLoading={setShowLoader} />
      ) : (
        <div className="signout-wrapper">
          <p>Are you sure you would like to sign out?</p>
          <p id="signout-text">
            Your all history will be cleared and you'll need to sign in again
          </p>

          <div className="signout-btn">
            <button
              onClick={() => manageSignOut()}
              className="wallet-common-button"
            >
              Yes
            </button>
            <button
              onClick={() => setShowSignOut(false)}
              className="wallet-common-button signout-btn-02"
            >
              No
            </button>
          </div>
        </div>
      )}
    </>
  );
}
