import { Route, Routes } from "react-router-dom";
import { SplashWallet } from "./pages/Wallet";

export function WrapperProvider() {
  return (
    <Routes>
      <Route path="/" element={<SplashWallet />} />
    </Routes>
  );
}
