import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValidSuiAddress } from "@mysten/sui.js/dist/cjs/utils";
import CloseIcon from "../../../../../assets/img/close.svg";
import {
  Context,
  TxnContext,
  client_network,
  toastMsg,
} from "../../../../lib/helper";
import "../../common.css";
import TxnSend from "../TxnSend";
import { TransactionForm } from "../Transaction";

export function QrScanner(props: any) {
  const { keyPair, coinList } = props;
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showTxnSend, setShowTxnSend] = useState(false);
  const [showReader, setShowReader] = useState(true);
  const { setShowScanner, walletAddress, setIsTxnSuccess, coinValue } =
    useContext(Context);

  const onCommVerify = (getData: any, values: any) => {
    if (values[4].toLowerCase() == "testnet") {
      setScanResultWebCam(getData);
      setShowReader(false);
    } else {
      toastMsg("OOPS! You should have to switch network");
      setShowScanner(false);
    }
  };

  const onPaybylinksVerify = (getData: any, values: any) => {
    if (values[4].toLowerCase() == "testnet") {
      if (values[1] == walletAddress) {
        setScanResultWebCam(getData);
        setShowReader(false);
      } else {
        toastMsg("OOPS! You're not eligible for payment");
        setShowScanner(false);
      }
    } else {
      toastMsg("OOPS! You should have to switch network");
      setShowScanner(false);
    }
  };

  // const onIdoVerify = (getData: any, values: any) => {
  //   if (values[4].toLowerCase() == client_network) {
  //     if (values[2] == walletAddress) {
  //       setScanResultWebCam(getData);
  //       setShowReader(false);
  //     } else {
  //       toastMsg("OOPS! You're not eligible for investing into this ido");
  //       setShowScanner(false);
  //     }
  //   } else {
  //     toastMsg("OOPS! You should have to switch network");
  //     setShowScanner(false);
  //   }
  // };

  const parseToJson = (scanData: any) => {
    const parsedData = JSON.parse(scanData);
    const values = Object.values(parsedData);
    // console.log(scanData, 'data');
    if (
      scanData.includes("merchant_address") &&
      scanData.includes("merchant_name") &&
      scanData.includes("order_id") &&
      scanData.includes("amount") &&
      scanData.includes("network") &&
      scanData.includes("blockchain") &&
      scanData.includes("coin_name") &&
      // scanData.includes("coin_type") &&
      values.length == 7
    ) {
      onCommVerify(scanData, values);
      // console.log('working');

    } else if (
      scanData.includes("merchant_address") &&
      scanData.includes("merchant_name") &&
      scanData.includes("machine_id") &&
      scanData.includes("provider_id")
    ) {
      setScanResultWebCam(scanData);
      console.log(scanData, 'demo');

      setShowTxnSend(true);
      setShowReader(false);
    }
    else if (
      scanData.includes("customer_address") &&
      scanData.includes("merchant_address") &&
      scanData.includes("order_id") &&
      scanData.includes("amount") &&
      scanData.includes("network") &&
      scanData.includes("merchant_name") &&
      scanData.includes("blockchain") &&
      scanData.includes("coin_name") &&
      values.length == 8

    ) {
      onPaybylinksVerify(scanData, values)
    }


    else {
      toastMsg("OOPS!! That doesn't look like valid QR Code");
    }
  };

  const handleScan = (scanData: any) => {
    const aptosAddressPattern = /^0x[a-fA-F0-9]{64}$/;
    const isAptosAddress = aptosAddressPattern.test(scanData);

    if (isAptosAddress) {
      setScanResultWebCam(scanData);
      setShowTxnSend(true);
      setShowReader(false);
    } else {
      parseToJson(scanData);
    }
  };


  // const handleScan = (scanData: any) => {
  //   const isSuiAddress = isValidSuiAddress(scanData);
  //   if (isSuiAddress) {
  //     setScanResultWebCam(scanData);
  //     setShowTxnSend(true);
  //     setShowReader(false);
  //   } else {
  //     parseToJson(scanData);
  //   }
  // };

  return (
    <>
      <ToastContainer />
      {showReader ? (
        <>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="qr-scan-grid"
          >
            <QrReader
              scanDelay={100}
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {
                if (!!result) {
                  handleScan(result?.text as any);
                }
              }}
            />
          </Grid>
          <img
            src={CloseIcon}
            alt="close"
            id="close_QR"
            onClick={() => setShowScanner(false)}
          />
        </>
      ) : showTxnSend ? (
        <TxnSend
          identityKey={scanResultWebCam}
          coinValue={coinValue}
          coinList={coinList}
          setShowTxnSend={setShowScanner}
          keyPair={keyPair}
          setIsTxnSuccess={setIsTxnSuccess}
        />
      ) : (
        <TxnContext.Provider
          value={{ scanResultWebCam, keyPair, setIsTxnSuccess }}
        >
          <TransactionForm />
        </TxnContext.Provider>
      )}
    </>
  );
}
