import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import BoltBlackLogo from "../../../../../assets/img/bb_trans.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import { toastMsg } from "../../../../lib/helper";
import CloseIcon from "../../../../../assets/img/close.svg";

const TxnReceive = (props: any) => {
  const { setShowTxnReceive, walletAddress } = props;

  const onCopyText = () => {
    navigator.clipboard.writeText(walletAddress);
    toastMsg("Copied!");
  };

  return (
    <div className="txn-receive-wrapper ">
      <img className="inner_header"
        src={CloseIcon}
        alt="close"
        onClick={() => setShowTxnReceive(false)}
      />
      <h6>Scan QR code, transfer SUI</h6>
      <QRCodeCanvas
        className="qr-canvas-style"
        value={walletAddress}
        size={200}
        includeMargin
        imageSettings={{
          src: BoltBlackLogo,
          x: undefined,
          y: undefined,
          height: 30,
          width: 30,
          excavate: true,
        }}
      />
      <div className="receive_address_copy">
        <div className="receive_wallet_address">
          <a>{walletAddress}</a>
        </div>
        <div className="wallet_address_copy">
          <p>copy</p>
          <img
            src={CopyIcon}
            className="copy_icon"
            onClick={() => onCopyText()}
          />
        </div>

      </div>
    </div>
  );
};

export default TxnReceive;
