import { useEffect, useState } from "react";
import { wordlist } from "@scure/bip39/wordlists/english";
import * as bip39 from "@scure/bip39";

import { validateMnemonic as bip39ValidateMnemonic } from "@scure/bip39";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dashboard } from "./componets/Dashboard";
import AppLogo from "../../../assets/img/logo.svg";
import addToHome from "../../../assets/img/AddTohome.svg";
import suiLogo from "../../../assets/img/aptos.svg";
import {
  Loader,
  client_network,
  client_network_url,
  encrypted_data,
  toastMsg,
} from "../../lib/helper";
import CopyIcon from "../../../assets/img/copy.svg";
import "./common.css";
import { AptosAccount } from "aptos";

// Status Functionality - default: 0, generate: 1 , scan: 2, txn: 3, completed: 4, error: 5

export function SplashWallet() {
  const getNetwork = localStorage.getItem("network:name");
  const [showJoinWallet, setShowJoinWallet] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const [loading, setLoading] = useState(false);

  // identifyDevice
  const identifyDevice = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    } else if (/android/.test(userAgent)) {
      setIsAndroidDevice(true);
      return "Android";
    } else if (/macintosh/.test(userAgent) && "ontouchend" in document) {
      return "iOS";
    } else if (/macintosh/.test(userAgent)) {
      return "Mac";
    } else if (/windows/.test(userAgent)) {
      setIsAndroidDevice(true);
      return "Windows";
    } else {
      setIsAndroidDevice(true);
      return "Unknown";
    }
  };

  useEffect(() => {
    if (getNetwork == null) {
      // setLoading(true);
      // processJWT();
      identifyDevice();
    }
  }, []);

  return (
    <div className="app-container">
      {loading ? (
        <Loader setLoading={setLoading} />
      ) : (
        <>
          {getNetwork !== "" &&
            getNetwork !== null &&
            getNetwork !== undefined ? (
            <Dashboard />
          ) : (
            <>
              {showJoinWallet ? (
                <JoinWallet />
              ) :
                (
                  <div className="splash-wrapper">
                    <img src={AppLogo} className="app-logo" alt="BlockBolt" />
                    <p>Blockchain Payment Protocol</p>
                    <p>
                      BlockBolt is a decentralized payment protocol designed to
                      facilitate fast, secure, and cost-effective transactions on
                      the Aptos Blockchain.
                    </p>
                    <button
                      className="wallet-common-button"
                      onClick={() => setShowJoinWallet(true)}
                    >
                      Let's Started
                    </button>
                    <div className="powered_by">
                      <p>Powered by</p>
                      <img src={suiLogo} className="sui-logo" alt="BlockBolt" />
                    </div>
                    {isAndroidDevice ? <AndroidHomeModal /> : <IosHomeModal />}
                  </div>
                )}
            </>
          )}
        </>
      )}
    </div>
  );
}

function IosHomeModal() {
  return (
    <>
      <div className="homeBg"></div>
      <div className="homeScreenPopup">
        <img src={addToHome} alt="BlockBolt" />
        <h4>Add To Home Screen</h4>
        <p>To install the mobile app</p>
        <p>
          tap the Share icon in your browser and choose{" "}
          <span>"Add to Home Screen"</span>
        </p>
      </div>
    </>
  );
}

function AndroidHomeModal() {
  // const openPlayStore = () => {
  //   window.open(
  //     "https://play.google.com/store/apps/details?id=com.blockbolt.suiwallet",
  //     "_blank"
  //   );
  // };
  return (
    <>
      <div className="homeAndroidBg"></div>
      <div className="androidHomeScreenPopup">
        <img src={addToHome} alt="BlockBolt" />
        <h4>Add To Home Screen</h4>
        <p>To install the mobile app</p>
        <p>
          you need to add this website to your home screen
          {/* or{" "} */}
          {/* <span onClick={() => openPlayStore()}>"Install Android App"</span> */}
        </p>
      </div>
    </>
  );
}

function JoinWallet() {
  const [showImportWallet, setShowImportWallet] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showLoading, setShowLoading] = useState(false);



  return (
    <>
      {showImportWallet ? (
        <ImportWallet setShowImportWallet={setShowImportWallet} />
      ) : showCreateWallet ? (
        <CreateWallet setShowCreateWallet={setShowCreateWallet} />
      ) : (
        <div className="wallet-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <div className="wallet-content">
            <p>Contactless and Decentralized: BlockBolt’s Innovative Payment Approach</p>
          </div>
          <div className="threeBottomBtns">
            <button
              className="wallet-common-button"
              onClick={() => setShowCreateWallet(true)}
            >
              Create Wallet
            </button>
            <button
              className="wallet-common-button"
              onClick={() => setShowImportWallet(true)}
            >
              Import Wallet
            </button>

          </div>
          <div className="powered_by">
            <p>Powered by</p>
            <img src={suiLogo} className="sui-logo" alt="BlockBolt" />
          </div>
        </div>
      )}
    </>
  );
}

function ImportWallet(props: any) {
  const { setShowImportWallet } = props;
  const [importWords, setImportWords] = useState("");
  const [showWallet, setShowWallet] = useState(false);

  const importWallet = () => {
    if (importWords !== "") {
      const TEST_MNEMONICS = importWords.toLowerCase();
      if (
        bip39ValidateMnemonic(TEST_MNEMONICS, wordlist) &&
        (TEST_MNEMONICS.split(" ") || []).length === 12
      ) {
        localStorage.setItem("wallet:mnemonic", encrypted_data(importWords));
        localStorage.setItem("wallet:gin", "no");
        localStorage.setItem("network:name", encrypted_data(client_network));
        localStorage.setItem("network:url", encrypted_data(client_network_url));
        setShowWallet(true);
        toastMsg("Wallet imported successfully");
      } else {
        toastMsg("There is no wallet related to those mnemonic");
      }
    } else {
      toastMsg("Something went wrong");
    }
  };

  return (
    <>
      <ToastContainer />
      {showWallet ? (
        <Dashboard />
      ) : (
        <div className="import-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <p className="import-content">
            Import your existing wallet by entering the 12-word recovery phrase
          </p>
          <textarea
            rows={3}
            className="form-control"
            placeholder="force kit end perfect concert cluster fever gap midnight chunk treat tiny"
            value={importWords}
            onChange={(e) => setImportWords(e.target.value)}
          />
          <div className="twoBottomBtns">
            <button
              className="wallet-common-button"
              onClick={() => importWallet()}
            >
              Import Wallet
            </button>
            <button
              className="wallet-common-button borderBtn"
              onClick={() => setShowImportWallet(false)}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function CreateWallet(props: any) {
  const { setShowCreateWallet } = props;
  const [mnemonic, setMnemonic] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  const [haveSaved, setHaveSaved] = useState(false);

  const onCopyText = () => {
    navigator.clipboard.writeText(mnemonic);
    toastMsg("Copied!");
  };

  const createWallet = () => {

    if (mnemonic !== "") {
      const TEST_MNEMONICS = mnemonic;
      const derivationPath = `m/44'/637'/0'/0'/0'`;
      const keypair = AptosAccount.fromDerivePath(derivationPath, TEST_MNEMONICS);
      if (keypair) {
        if (
          bip39ValidateMnemonic(TEST_MNEMONICS, wordlist) &&
          (TEST_MNEMONICS.split(" ") || []).length === 12
        ) {
          localStorage.setItem("wallet:mnemonic", encrypted_data(mnemonic));
          localStorage.setItem("wallet:gin", "no");
          localStorage.setItem("network:name", encrypted_data(client_network));
          localStorage.setItem(
            "network:url",
            encrypted_data(client_network_url)
          );
          setShowWallet(true);
          toastMsg("Wallet created successfully");
        } else {
          toastMsg("There is no wallet related to those mnemonic");
        }
      }
    } else {
      toastMsg("Something went wrong");
    }
  };

  useEffect(() => {
    const getMnemonic = bip39.generateMnemonic(wordlist);
    setMnemonic(getMnemonic);
  }, []);

  return (
    <>
      <ToastContainer />
      {showWallet ? (
        <Dashboard />
      ) : (
        <div className="create-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <p className="create-content">
            Your recovery phrase makes it easy to back up and restore your
            account.
          </p>
          <div className="create-ib-wrapper">
            <textarea
              rows={3}
              className="form-control "
              value={mnemonic}
              readOnly
            />
            <img
              src={CopyIcon}
              className="copy_icon"
              onClick={() => onCopyText()}
            />
          </div>
          <div className="recoveryCheckbox">
            <input type="checkbox" onClick={() => setHaveSaved(!haveSaved)} />
            <p>I saved my recovery phrase</p>
          </div>
          <div className="twoBottomBtns">
            <button
              className={
                haveSaved
                  ? "wallet-common-button"
                  : "wallet-common-button disable"
              }
              onClick={() => haveSaved && createWallet()}
            >
              Create Wallet
            </button>
            <button
              className="wallet-common-button borderBtn"
              onClick={() => setShowCreateWallet(false)}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
